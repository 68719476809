// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-type-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/templates/type.js" /* webpackChunkName: "component---src-templates-type-js" */),
  "component---src-pages-artskart-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/artskart.js" /* webpackChunkName: "component---src-pages-artskart-js" */),
  "component---src-pages-artskart-bidragsytere-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/artskart/bidragsytere.js" /* webpackChunkName: "component---src-pages-artskart-bidragsytere-js" */),
  "component---src-pages-artskart-bildebruk-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/artskart/bildebruk.js" /* webpackChunkName: "component---src-pages-artskart-bildebruk-js" */),
  "component---src-pages-artskart-dataformat-og-nedlasting-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/artskart/dataformat_og_nedlasting.js" /* webpackChunkName: "component---src-pages-artskart-dataformat-og-nedlasting-js" */),
  "component---src-pages-artskart-kvalitetssikring-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/artskart/kvalitetssikring.js" /* webpackChunkName: "component---src-pages-artskart-kvalitetssikring-js" */),
  "component---src-pages-artskart-retningslinjer-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/artskart/retningslinjer.js" /* webpackChunkName: "component---src-pages-artskart-retningslinjer-js" */),
  "component---src-pages-artsnavn-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/artsnavn.js" /* webpackChunkName: "component---src-pages-artsnavn-js" */),
  "component---src-pages-fremmedarter-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/fremmedarter.js" /* webpackChunkName: "component---src-pages-fremmedarter-js" */),
  "component---src-pages-index-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rodliste-naturtyper-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/rodliste_naturtyper.js" /* webpackChunkName: "component---src-pages-rodliste-naturtyper-js" */),
  "component---src-pages-rodlistearter-js": () => import("/home/b/src/adb/data.artsdatabanken.no/src/pages/rodlistearter.js" /* webpackChunkName: "component---src-pages-rodlistearter-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/b/src/adb/data.artsdatabanken.no/.cache/data.json")

